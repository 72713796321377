@import "../../global.scss";

.navbar{
    position: sticky;
    top: 0px;
    z-index: 100;

    background-color: white;

    transition: all .2s;
}

.navbar__item{
    padding: 15px 15px 15px 15px;
    border-radius: 12px;
    transition: all .2s;
}

.navbar__item:hover{
    cursor: pointer;
    background-color: $primary-light;
}