@import '../../global.scss';

.products{
  min-height: 100vh;
}

.products__section{
  margin-bottom: 150px;
}

.products__ques{
  margin: 30px 0px;
  background-color: $primary-light!important;
  border-radius: 25px;
  padding: 15px;
  transition: all .3s;
}

.products__benefits{
  margin: 0px 0px 70px 0px;
  padding: 15px;
  background-color: $primary-light!important;
  border-radius: 25px;
  padding: 15px;
  transition: all .3s;
}