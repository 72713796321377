@import "../../global.scss";

.home{
    min-height: 100vh;

    &__links {
        padding-bottom: 20px;
        cursor: pointer;

        &:hover{
            text-decoration: underline;
        }
    }
}

.home__top{
    height: calc(100vh - 180px);
    margin-bottom: 10px;
}

.home__text{
    overflow: hidden;
    height: 0px; 
    color: $secondary!important;
}


.home__products{
    margin-bottom: 50px;

    background-color: #ebf4f7;
    padding: 60px;
    // border-radius: 12px;

    &-cards{
        // height: 350px;
        background-color: $white;
        border-radius: 25px;
        padding: 25px;
        transition: all .3s;

        box-shadow: rgba(102, 136, 170, 0.2) 0px 8px 24px;

        &:hover{
            scale: 1.04;
            // cursor: pointer;
        }
    }

    &-solution{
        height: 150px;
        background-color: $secondary-dark;
        color: $primary-light!important;
        border-radius: 25px;
        padding: 15px;
        transition: all .3s;
    }

    &-features{
        height: 100%;
        background-color: $primary-light;
        border-radius: 25px;
        padding: 15px;
        transition: all .3s;
    }
}

.home__solutions{
    margin-bottom: 120px;
}

.home__developers{
    margin-bottom: 100px;
}