@import "../../global.scss";

.contact{
    margin-bottom: 150px;
}

.contact__map{
    border-radius: 12px;
    width: 100%;
    height: 60vh;
}