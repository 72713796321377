@import "../../global.scss";

.plan_containter {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    max-width: 1400px;
    margin: 0 auto;
    padding: 20px;

    table {
        width: 100%;
        border-collapse: collapse;
    }
    table, th, td {
        border: 1px solid #ddd;
        padding: 10px;
    }
}
.plans {
    display: flex;
    justify-content: space-between;
}
.plan {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    width: 30%;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}
.cta {
    text-align: center;
    margin-top: 30px;
}
.button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
}
.price {
    font-size: 24px;
    font-weight: bold;
    color: #007bff;
}
.per-month {
    font-size: 14px;
    color: #666;
}
.toggle-container {
    text-align: center;
    margin-bottom: 20px;
}
.toggle {
    display: inline-flex;
    align-items: center;
    background-color: #f0f0f0;
    border-radius: 20px;
    padding: 5px;
}
.toggle input[type="radio"] {
    display: none;
}
.toggle label {
    cursor: pointer;
    padding: 5px 15px;
    border-radius: 15px;
    transition: background-color 0.3s, color 0.3s;
}
.toggle label.active {
    background-color: #007bff;
    color: white;
}

.pricing{

    &__card{
        height: 100%;
        background-color: $white;
        border-radius: 25px;
        padding: 25px;
        transition: all .3s;

        box-shadow: rgba(102, 136, 170, 0.2) 0px 8px 24px;

        &:hover{
            scale: 1.04;
            cursor: pointer;
        }
    }
}