$white: #ffffff;
$black: #000000;

// main colors
$primary-light:#e2f2f9;
$primary: #acd7fa;
$primary-dark: #8ecce7;
// $primary: #74aaf7;

// $secondary: #1a2c5b;
// $secondary: #74aaf7;

$secondary-light: #253f83;
$secondary: #0a66c2;
$secondary-dark: #0f1933;

// $secondary: #00529c;
// $secondary: #006039;
// $secondary: #0866fa;
// $secondary-dark: #0866ff;


// flags
$success: #2e7d32;
$error: #d32f2f;
$warning: #ed6c02;

// text
$black-text: #000000;
$main-text: #0f1933;
$sub-text: #616161;