* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

body{
  // font-family: 'Urbanist', sans-serif;
  font-family: "Plus Jakarta Sans", sans-serif;
}

html { scroll-behavior: smooth; } 